<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
export default {
    page: {
        title: "Team",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            title: "Transaction Pin",
            items: [],
            pin: null,
            pinc: null,
            tryingToLogIn: false,
            password: null,
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
    },


created() {
    this.checklogged();
},
    methods: {
                     async setpin() {



       this.tryingToLogIn = true;
try {
  
       
  var data = await this.$store.dispatch('setpin', {transpin: this.pin,transpin_confirmation: this.pinc, password: this.password});  

     this.tryingToLogIn = false;
       Swal.fire("Success", "Transaction pin Has Been Set Successfully", "success");
   this.$router.push({name: 'dashboard'});
  if (data.status == false) {
    //  return this.authError == data.message;

  }            


       
} catch (error) {
   console.log(error.response);
    this.tryingToLogIn = false;
if (error.response != undefined) {

if (error.response.status == 422) {
      Swal.fire("Error", "Please make sure field are correct", "error");
} 

Swal.fire("Error", error.response.data.data.message, "error");
       
   return this.authError = error.response.data.data.message
} 


else {
      Swal.fire("Error", "Please make sure field are correct", "error");
   return this.authError = "Please make sure You pin matches"
}


 
}

},
 
       async checklogged() {

  await axios.get(process.env.VUE_APP_MAIN_URL+ "/auth/me").then((data) => {




    localStorage.setItem('userdata', JSON.stringify(data.data.data))


     this.$store.commit('setUser', {
      token: localStorage.getItem('accessToken') ,
      refresh: localStorage.getItem('accessToken') ,
      user:  data.data.data,
    
} )  
  



  }).catch(() => {
    this.$store.commit('SetAuthLogout')
   
       this.$router.push({name: 'login'});
  });




        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
    
    
        <div class="row g-2 ">
          
              

                
                <div class="col-md-6 mx-auto">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Transaction Pin</h4>
            
          </div>
          <!-- end card header -->
          <div class="card-body">
          
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="mb-3">
                  <label for="password" class="form-label"
                    >Your Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="password"
                    placeholder="Your Password"
                  />
                </div>
               <div class="mb-3">
                  <label for="pin" class="form-label"
                    >Transaction Pin</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="pin"
                       maxlength="6"
                    min="4"
                    v-model="pin"
                    placeholder="Transaction Pin"
                  />
                </div>
                <div class="mb-3">
                  <label for="cpin" class="form-label"
                    >Confirm Transaction Pin</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="cpin"
                    maxlength="6"
                    min="4"
                    v-model="pinc"
                    placeholder="Confirm Transaction Pin"
                  />
                </div>
                
           
                <div class="text-end">
                  <button type="submit " @click="setpin" :disabled="tryingToLogIn" class="btn btn-primary">

                   <div class="spinner-border spinner-border-sm" role="status" v-if="tryingToLogIn"> 
  <span class="sr-only">Loading...</span>
</div>

                    Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                </div>
                
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>
